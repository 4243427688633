import i18next from "../i18n";

const { t } = i18next;

/** Given an product ID, return a user-friendly name. */
export function productDisplayName(productId: string): string {
  switch (productId) {
    case "tram":
      return "TRAM";
    case "property-claim-handler":
      return t("product.property_claim_handler");
    default:
      return productId;
  }
}
